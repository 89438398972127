<template>
  <div>
    <div class="video-container">
      <video controls autoplay muted>
        <source src="@/assets/drive-video.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="text-container">
      <div class="title">ドライビングレッスン</div>
      <div class="main">
        <p>
          ドライビングレッスンスクール体験
          モータースポーツ体験及エンターテイメントプロジェクトとして、ドライビングスクールは海外でも人気が高まっています.娯楽、スリルを組み合わせた一種のスポーツアクティビティであり、車好きの方がに愛されています。
        </p>
        <p>
          より多くのお客様にこのスポーツアクティビティを楽しんで頂くために、当社は一般の方向けに対応する2種類のサーキットドライビングスクール体験ができるトレーニングコースを提供しています。
        </p>
        <!-- <br /> -->
        <p>
          ①ヴァーチャルコース：レーシングシミュレーターを使用したサーキットでの基本的なドライビング知識の講習。
        </p>
        <p>
          ②リアルコース：実際のサーキットを使ってリアルな走りをレッスンしながら走行体感。
        </p>
        <p>
          さまざまなお客様向けにパーソナライズされた専門的なドライビングレッスンコースを開発致します。
          お楽しみに。
        </p>
      </div>
    </div>

    <div style="display: flex" class="img-container">
      <img src="@/assets/drive1.jpg" alt="" style="margin-left: 0" />
      <img src="@/assets/drive2.jpg" alt="" />
      <img src="@/assets/drive3.jpg" alt="" style="margin-right: 0" />
    </div>
    <div style="display: flex" class="img-container">
      <img src="@/assets/drive4.jpg" alt="" style="margin-left: 0" />
      <img src="@/assets/drive5.jpg" alt="" />
      <img src="@/assets/drive6.jpg" alt="" style="margin-right: 0" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileDrive'
}
</script>

<style lang="scss" scoped>
.video-container {
  height: 200px;
  video {
    display: block;
    height: 100%;
    margin: 5px auto;
  }
}

.text-container {
  margin: 10px auto;
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .main {
    padding: 8px;
    font-size: 14px;
    text-indent: 2em;
    text-align: justify;
    border: 2px solid #fff;
    p {
      margin: 8px 0;
    }
  }
}

.img-container {
  margin: 10px 0;
  img {
    margin: auto;
    width: 32%;
    height: 80px;
    box-shadow: 1px 0 2px 2px rgb(0, 0, 0),
      3px 2px 3px 2px rgb(255, 255, 255, 0.7);
  }
}
</style>
